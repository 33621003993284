<template>
  <div>
    <base-module :moduleOptions="options" title="Usuarios" ref="module">
      <template v-slot:recorddetails="slotProps">
        <div class="flex flex-col space-y-2">
          <h3>{{ slotProps.name }}</h3>
          <div>
            <strong>Usuario</strong>
            {{ slotProps.username }}
          </div>
          <div class="flex space-x-4">
            <div class="flex-1">
              <span class="font-semibold">email:</span> {{ slotProps.email }}
            </div>
            <div class="flex-1" v-if="slotProps.profile">
              <span class="font-semibold">Perfil:</span>
              {{ slotProps.profile.name }}
            </div>
          </div>
        </div>
      </template>
    </base-module>
    <user-form :handlePostSubmit="handlePostSubmit" :record="selectedRow" />
  </div>
</template>

<script>
import BaseModule from "@/components/Base/BaseModule";
import UserForm from "./UserForm";
import { customComponentActions } from "@/mixins/actions.js";
export default {
  name: "Users",
  inject: ["usersRepository", "profilesRepository"],

  mixins: [customComponentActions],

  components: {
    BaseModule,
    UserForm
  },

  data() {
    return {
      options: {
        columns: [
          {
            header: "Nombre completo",
            prop: "name"
          },
          {
            header: "Usuario",
            prop: "username"
          },
          {
            header: "Email",
            prop: "email"
          },
          {
            header: "Perfil",
            prop: "profile.name"
          }
        ],
        repository: {
          name: "usersRepository"
        },
        sortable: ["name", "username", "email", "profile.name"],
        primary: "id"
      },

      selectedRow: null
    };
  },

  methods: {
    handlePostSubmit() {
      this.$refs.module.handleLoadData();
    }
  }
};
</script>

<style lang="scss" scoped></style>
