<template>
  <base-modal-form
    title="Crear"
    ref="modalform"
    @postSubmit="handlePostSubmit"
    @show="handleShow"
  >
    <template v-slot:formfields>
      <input type="hidden" name="id" v-model="formFields.id" />
      <div class="flex space-x-4">
        <b-form-group
          label="Nombre completo"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.name)"
        >
          <b-form-input
            name="name"
            v-model.trim="$v.formFields.name.$model"
            :state="validateState('name')"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label="Usuario"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.username)"
        >
          <b-form-input
            name="username"
            v-model.trim="$v.formFields.username.$model"
            :state="validateState('username')"
          >
          </b-form-input>
        </b-form-group>
      </div>
      <div class="flex space-x-4">
        <b-form-group
          label="Correo electrónico"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.email)"
        >
          <b-form-input
            name="email"
            v-model.trim="$v.formFields.email.$model"
            :state="validateState('email')"
          >
          </b-form-input>
        </b-form-group>
        <b-form-group
          label="Contraseña"
          class="flex-1 required"
          :invalidFeedback="validateField($v.formFields.password)"
        >
          <b-form-input
            name="password"
            type="password"
            v-model.trim="$v.formFields.password.$model"
            :state="validateState('password')"
          >
          </b-form-input>
        </b-form-group>
      </div>
      <div class="flex space-x-4">
        <b-form-group label="Perfil" class="flex-1 required">
          <input
            type="hidden"
            name="profile_id"
            v-model="formFields.profile_id"
          />
          <v-select
            :options="profiles"
            :reduce="profile => profile.value"
            label="text"
            v-model.trim="formFields.profile_id"
          />
        </b-form-group>
      </div>
    </template>
  </base-modal-form>
</template>

<script>
import BaseModalForm from "@/components/Base/BaseModalForm";
import { required, email, minLength } from "vuelidate/lib/validators";
import { customComponentActions } from "@/mixins/actions.js";
import { deserialize } from "jsonapi-fractal";

export default {
  props: ["handlePostSubmit", "record"],
  mixins: [customComponentActions],
  inject: ["usersRepository", "profilesRepository"],

  components: {
    BaseModalForm
  },

  data() {
    return {
      formFields: {
        id: null,
        name: "",
        username: "",
        email: "",
        profile_id: null,
        password: ""
      },

      profiles: [],

      isString: ["password"],

      repository: "usersRepository",
      primary: "id"
    };
  },

  validations() {
    const me = this;
    let defaultValidation = {
      name: {
        required
      },
      username: {
        required
      },
      email: {
        required,
        email,
        minLength: minLength(3)
      }
    };

    return me.record
      ? {
          formFields: {
            password: {
              minLength: minLength(8)
            },
            ...defaultValidation
          }
        }
      : {
          formFields: {
            password: {
              required,
              minLength: minLength(8)
            },
            ...defaultValidation
          }
        };
  },

  methods: {
    handleShow() {
      const me = this;

      if (me.record) {
        me.formFields = { ...me.record };
      }
    }
  },

  async mounted() {
    const me = this;

    let results = await me.profilesRepository.get();

    me.profiles = [
      {
        value: null,
        text: "Seleccione perfil"
      },
      ...deserialize(results.data).map(item => {
        return {
          value: item.id,
          text: item.name
        };
      })
    ];
  }
};
</script>

<style lang="scss" scoped></style>
